import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  Content,
  ImageCollection,
} from "@bluefin/components";
import { Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";
import "../style/about.css";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"about-hero"}>
              <Background.Image
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
              />
            </Background>
          }
          subfooter={false}
          className={"about-page"}
        >
          <Content className={"about-content"}>
            <Content.Markup isMarkdown={false} textAlign={"center"} width={16}>
              <Header as={"h1"}>About Us</Header>
            </Content.Markup>
            <Content.Markup width={16}>
              {fishermanBusiness.aboutMarkdown}
            </Content.Markup>
          </Content>
          <ImageCollection
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "ImageCollection",
            })}
            as={"gallery"}
            centered={true}
            cutUnevenRows={true}
            smartImageGrouping={false}
            disable={"none"}
            header={""}
            numberOfRowDisplayItems={3}
          />
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      aboutMarkdown
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
